import { useTranslation } from 'next-i18next';

import { useCallback, useEffect, useState } from 'react';

import { Button } from '@chakra-ui/button';
import { Box, Flex, GridItem, HStack, Text, VStack } from '@chakra-ui/layout';
import { useMediaQuery } from '@chakra-ui/media-query';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';

import { urlHelpers } from '@nocowanie/core';
import { Avatar, GridWrapper, searchEngineIsMobileSsrBreakpoint } from '@nocowanie/common-ui';
import { IconsLinear } from '@nocowanie/icons';

import { SearchEngineWrapper } from '@app/components/molecules/search-engine-wrapper';
import { Navigation, NavigationMobile } from '@app/components/organisms/navigation';
import { MarketingBanner } from '@app/esi-components';
import { getDashboardLink, logOutUser } from '@app/helpers/navigation.helpers';
import { useUserProfile } from '@app/hooks';
import { NavGroupModel } from '@app/models';

import { useAppHeaderHook } from './app-header.hook';
import { AppHeaderProps } from './app-header.props';
import { AppHeaderLogo } from './app-header-logo';

import staticData from '../../../assets/data/static-data.json';
import navLinks from '../navigation/navigation-links.json';

export const AppHeader = ({
    isSimplified = true,
    showSearchEngine = false,
    isHomePage = false,
    isV7Theme = false,
}: AppHeaderProps) => {
    const { t } = useTranslation('common', {
        keyPrefix: 'appHeader',
    });
    const { isMobileRes } = useAppHeaderHook();
    const isMobileHomepage = isHomePage && isMobileRes;
    const isGhostVariant = isMobileHomepage && isV7Theme;
    const isOldMobileVariant = isMobileHomepage && !isV7Theme;
    const { user } = useUserProfile();

    const [isProfileOpen, setIsProfileOpen] = useState(false);

    const handleLogout = useCallback(() => {
        logOutUser();
    }, []);

    const handleCloseProfile = useCallback(() => {
        setIsProfileOpen(false);
    }, []);

    const handleOpenProfile = useCallback(() => {
        setIsProfileOpen(true);
    }, []);

    useEffect(() => {
        if (!isProfileOpen) {
            return;
        }

        window.addEventListener('scroll', handleCloseProfile);

        return () => {
            window.removeEventListener('scroll', handleCloseProfile);
        };
    }, [handleCloseProfile, isProfileOpen]);

    const links = navLinks as NavGroupModel[];

    const [isMobileSearchEngineVersion] = useMediaQuery(
        `(max-width: ${searchEngineIsMobileSsrBreakpoint})`,
    );

    const ContactInfo = () => (
        <>
            <Text
                hideBelow={'xl'}
                fontSize={'sm'}
                mr={2}
                alignSelf={'flex-end'}
                dangerouslySetInnerHTML={{ __html: t('callUs') }}
            />
            <VStack fontSize={'xs'} align={'flex-start'} gap={1} hideBelow={'lg'}>
                <Text
                    fontSize={'sm'}
                    fontWeight={'bold'}
                    as="a"
                    href={urlHelpers.getPhoneNoHref(staticData.navigation.hotline)}
                >
                    <IconsLinear.Call mr={2} fontSize={'sm'} />
                    {staticData.navigation.hotline}
                </Text>
                <Text>
                    {staticData.navigation.openingDays}: {staticData.navigation.openingHours}
                </Text>
            </VStack>
        </>
    );

    const HeaderRightSection = () =>
        !isMobileRes ? (
            user ? (
                <>
                    <HStack gap={'gutterWidth'}>
                        <ContactInfo />
                    </HStack>
                    <Menu
                        isOpen={isProfileOpen}
                        onOpen={handleOpenProfile}
                        onClose={handleCloseProfile}
                    >
                        <MenuButton
                            as={Button}
                            rightIcon={<IconsLinear.ArrowDown2 fontSize={'sm'} />}
                            border={'none'}
                            _hover={{
                                bg: 'transparent',
                            }}
                            _active={{
                                bg: 'transparent',
                            }}
                            title={user.firstName || user.lastName || t('userMenu.defaultName')}
                            alignItems={'center'}
                        >
                            <Avatar src={user.avatar ?? undefined} />
                        </MenuButton>
                        <MenuList zIndex={5} color={'secondary.500'} fontSize={'sm'}>
                            <MenuItem
                                as={'a'}
                                href={getDashboardLink(user)}
                                _hover={{
                                    bg: 'sandYellow.50',
                                    textDecoration: 'none',
                                }}
                                _focus={{
                                    bg: 'sandYellow.50',
                                }}
                            >
                                {t('userMenu.goToPanel')}
                            </MenuItem>
                            <MenuItem
                                onClick={handleLogout}
                                _hover={{
                                    bg: 'sandYellow.50',
                                }}
                                _focus={{
                                    bg: 'sandYellow.50',
                                }}
                            >
                                {t('userMenu.logOut')}
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </>
            ) : (
                <HStack gap={'gutterWidth'}>
                    <ContactInfo />
                    <Button
                        as="a"
                        href={links[0].navLinks[1].url ?? '/'}
                        leftIcon={<IconsLinear.Add />}
                        colorScheme={'secondary'}
                        variant={'outline'}
                        ml={'2'}
                    >
                        {t('cta.addAccommodation')}
                    </Button>
                    <Button
                        as="a"
                        href={links[0].navLinks[0].url ?? '/'}
                        leftIcon={<IconsLinear.User />}
                        colorScheme={'brand'}
                        variant={'solid'}
                    >
                        {t('cta.logIn')}
                    </Button>
                </HStack>
            )
        ) : null;

    return (
        <>
            {isHomePage && new Date() <= new Date('2025-05-03T23:59:59') ? (
                // https://szallashugroup.atlassian.net/browse/PL-1963
                <MarketingBanner />
            ) : null}
            <Box
                as={'header'}
                py={isOldMobileVariant ? 0 : 2}
                borderBottom={isGhostVariant ? undefined : '1px solid'}
                borderBottomColor={'gray.200'}
                mb={isGhostVariant ? '-headerOffset' : undefined}
            >
                <GridWrapper>
                    {isSimplified ? (
                        <AppHeaderLogo isGhostVariant={isGhostVariant} />
                    ) : (
                        <HStack as={GridItem} gridColumn={'1 / -1'} justify={'space-between'}>
                            <HStack gap={isMobileRes ? 0 : 'gutterWidth'}>
                                {isMobileRes ? (
                                    <NavigationMobile
                                        menuLabel={t('menu.menuLabel')}
                                        closeLabel={t('menu.closeLabel')}
                                        user={user}
                                        isGhostVariant={isGhostVariant}
                                    />
                                ) : null}
                                <AppHeaderLogo
                                    isGhostVariant={isGhostVariant}
                                    isLogoV7={isV7Theme}
                                />
                                {!isMobileRes ? (
                                    <Navigation triggerText={t('menu.planYourStay')} />
                                ) : null}
                            </HStack>
                            {showSearchEngine ? (
                                isMobileSearchEngineVersion ? (
                                    <Flex gap={4}>
                                        <SearchEngineWrapper />
                                        <HeaderRightSection />
                                    </Flex>
                                ) : (
                                    <>
                                        <SearchEngineWrapper />
                                        <HeaderRightSection />
                                    </>
                                )
                            ) : (
                                <HeaderRightSection />
                            )}
                        </HStack>
                    )}
                </GridWrapper>
            </Box>
        </>
    );
};
