// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = "/monitoring";globalThis["SENTRY_RELEASE"] = {"id":"c1d74809aad28240aadf1a204037f42bd66ee649"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';

import { environment } from './environments/environment';
import project from './project.json';
import { baseClientSentryConfig } from './sentry.config';


Sentry.init({
    ...baseClientSentryConfig(environment.SENTRY),
});

Sentry.setTag(
    'project',
    environment.SENTRY.SENTRY_PROJECT_NAME ?? (project.name || 'UnknownProject'),
);
