import { createAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { addDays } from 'date-fns/addDays';
import { isEqual, isNil, set } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { dateHelpers, LoadingState, urlHelpers } from '@nocowanie/core';
import { DayConfigModel } from '@nocowanie/common-ui';

import { SliceNameEnum } from '@app/enums';
import { environment } from '@app/environments/environment';
import { getBestOffer } from '@app/helpers/accommodation.helpers';
import {
    AccommodationStateModel,
    RequestAdditionalParamsModel,
    ReservationParamsModel,
    ResponseModel,
} from '@app/models';
import {
    Apartment,
    Image,
    Maybe,
    QueryAccommodationArgs,
    ReasonCode,
    Variant,
} from '@app/models/api/api-types';
import { CalendarAvailabilityModel } from '@app/models/nop-api';
import { AccommodationService, ApiAccommodationService } from '@app/services';

export const fetchAccommodation = createAsyncThunk(
    `${SliceNameEnum.Accommodation}/fetch-accommodations`,
    async (
        args: (Partial<QueryAccommodationArgs> & RequestAdditionalParamsModel) | undefined,
        { getState },
    ) => {
        const { extraParams, ...queryArgs } = args ?? {};
        const stateFilters = selectors.selectFilters(
            getState() as { [SliceNameEnum.Accommodation]: AccommodationStateModel },
        );
        const filters = { ...stateFilters, ...(queryArgs ?? {}) };

        return await AccommodationService.instance.loadEntity(
            filters as QueryAccommodationArgs,
            extraParams?.headers,
        );
    },
);

export const fetchCalendarAvailability = createAsyncThunk<
    ResponseModel<CalendarAvailabilityModel>,
    { accommodationId?: number }
>(
    `${SliceNameEnum.Accommodation}/fetch-calendar-availability`,
    async ({ accommodationId }, { getState, rejectWithValue }) => {
        const stateFilters = selectors.selectFilters(
            getState() as { [SliceNameEnum.Accommodation]: AccommodationStateModel },
        );

        try {
            const id = accommodationId ?? urlHelpers.extractNumericId(stateFilters?.id || '');
            if (!id) {
                return rejectWithValue('AccommodationId is missing.');
            }

            return await ApiAccommodationService.instance.getAccommodationAvailability(id);
        } catch (err) {
            return rejectWithValue([err].flat());
        }
    },
);

const makeReservationAction = createAction<ReservationParamsModel>(
    `${SliceNameEnum.Accommodation}/make-reservation`,
);
const makeReservation$: BehaviorSubject<ReservationParamsModel | undefined> = new BehaviorSubject<
    ReservationParamsModel | undefined
>(undefined);

const initialState: AccommodationStateModel = {
    calendarAvailability: null,
    filters: null,
    isCalendarAvailabilityLoading: LoadingState.IDLE,
    loading: LoadingState.IDLE,
    bestOffer: null,
    selectedApartmentId: null,
    selectedVariant: null,
    rangePickerDayConfig: null,
    rangePickerExcludedDates: [],
    calendarAvailabilityErrors: null,
    calendarAvailabilityErrorText: undefined,
};

const slice = createSlice({
    name: SliceNameEnum.Accommodation,
    initialState,
    reducers: {
        updateFilters: (state, action) => {
            const newFilters = {
                ...state.filters,
                ...action.payload,
            };

            if (isEqual(state.filters, newFilters)) {
                return;
            }

            state.filters = newFilters;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setSelectedVariant: (state, action) => {
            state.selectedVariant = action.payload;
        },
        setSelectedApartmentId: (state, action) => {
            state.selectedApartmentId = action.payload;
        },
        setCalendarAvailabilityErrorText: (state, action) => {
            state.calendarAvailabilityErrorText = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchAccommodation.pending, state => {
            state.loading = LoadingState.PENDING;
        });
        builder.addCase(fetchAccommodation.fulfilled, (state, { payload }) => {
            if (!payload) {
                return;
            }

            const { data } = payload as any;
            const transformedData = { ...data };

            // Data transformation - Rounding ratePercentage
            data?.apartments?.forEach((apartment: Apartment, index: number) => {
                apartment?.variants?.forEach((variant: Maybe<Variant>, variantIndex: number) => {
                    if (isNil(variant)) {
                        return;
                    }
                    const ratePercentage = variant.cancellationRules.ratePercentage;
                    if (typeof ratePercentage !== 'number') {
                        return;
                    }

                    set(
                        transformedData,
                        `apartments[${index}].variants[${variantIndex}].cancellationRules.ratePercentage`,
                        Math.round(ratePercentage),
                    );
                });
            });

            state.currentAccommodation = { ...transformedData };
            state.bestOffer = getBestOffer(state.currentAccommodation?.apartments?.[0], {
                withoutPrepayment: state?.filters?.withoutPrepayment,
                freeCancel: state?.filters?.freeCancel,
                provision: state?.filters?.provision,
            });

            state.selectedVariant = null;
            state.selectedApartmentId = null;
            state.loading = LoadingState.IDLE;
        });
        builder.addCase(fetchAccommodation.rejected, (state, action) => {
            state.errors = [action.error].flat();
            state.loading = LoadingState.IDLE;
        });
        builder.addCase(makeReservationAction, (state, action) => {
            makeReservation$.next(action.payload);
        });
        builder.addCase(fetchCalendarAvailability.pending, state => {
            state.isCalendarAvailabilityLoading = LoadingState.PENDING;
        });
        builder.addCase(fetchCalendarAvailability.rejected, (state, action) => {
            state.isCalendarAvailabilityLoading = LoadingState.IDLE;
            state.calendarAvailabilityErrors = [action.payload as string];
        });
        builder.addCase(fetchCalendarAvailability.fulfilled, (state, action) => {
            if (action.payload?.error) {
                state.isCalendarAvailabilityLoading = LoadingState.IDLE;
                return;
            }

            state.calendarAvailability = action.payload;
            state.calendarAvailabilityErrorText = undefined;

            if (!state?.calendarAvailability?.calendarAvailability) {
                state.rangePickerDayConfig = null;
            }

            const rangePickerDayConfig: Record<string, DayConfigModel> = {};
            const excludeDates = new Set<string>();

            Object.entries(state.calendarAvailability.calendarAvailability)
                .filter(([, dayConfig]) => !isNil(dayConfig))
                .forEach(([dayString, dayConfig]) => {
                    const { minCutOff, ...config } = dayConfig;
                    const day = new Date(new Date(dayString).setHours(0, 0, 0, 0));
                    const noDeparture = dayConfig?.noDeparture;
                    let noArrival = dayConfig?.noArrival;

                    if (config?.full) {
                        const prevDay = addDays(day, -1);
                        const prevDayString = dateHelpers.format(
                            prevDay,
                            environment.API_DATE_FORMAT,
                        );

                        const prevDayConfig =
                            state.calendarAvailability?.calendarAvailability[prevDayString];

                        if (!prevDayConfig?.full) {
                            // We display first unavailable day as noArrival day
                            noArrival = true;
                        } else {
                            excludeDates.add(dayString);
                        }
                    }

                    rangePickerDayConfig[dayString] = {
                        ...config,
                        noDeparture,
                        noArrival,
                        minCutOff,
                    };
                });

            state.rangePickerDayConfig = rangePickerDayConfig;
            state.rangePickerExcludedDates = [...excludeDates];
        });
    },
});

const { actions: sliceActions } = slice;

const actions = {
    ...sliceActions,
    fetchAccommodation,
    makeReservation: makeReservationAction,
};

const selectSelfState = (state: { [SliceNameEnum.Accommodation]: AccommodationStateModel }) => {
    return state[slice.name] ?? initialState;
};
const selectVariantsForApartment = (apartmentId: string) =>
    createSelector(
        selectSelfState,
        state =>
            state?.currentAccommodation?.apartments?.find(
                (x: Maybe<Apartment>) => x?.id === apartmentId,
            )?.variants,
    );

const selectors = {
    selectLoading: createSelector(selectSelfState, state => state.loading),
    selectFilters: createSelector(selectSelfState, state => state?.filters),
    selectCurrentAccommodation: createSelector(
        selectSelfState,
        state => state.currentAccommodation,
    ),
    selectErrors: createSelector(selectSelfState, state => state.errors),
    selectApartmentById: (apartmentId: string | undefined) =>
        createSelector(selectSelfState, state =>
            isNil(apartmentId)
                ? undefined
                : state.currentAccommodation?.apartments?.find(
                      (x: Maybe<Apartment>) => x?.id === apartmentId,
                  ),
        ),
    selectVariantsForApartment,
    selectBestOffer: createSelector(selectSelfState, state => state.bestOffer),
    selectBestOfferUrl: createSelector(selectSelfState, state => {
        const { filters, currentAccommodation } = state;
        const firstApartment = currentAccommodation?.apartments?.[0];
        const bestOffer = state.bestOffer;

        if (isNil(bestOffer) || isNil(firstApartment)) {
            return undefined;
        }

        const checkoutQueryParams = {
            accommodationId:
                state.currentAccommodation &&
                urlHelpers.extractNumericId(state.currentAccommodation.id),
            checkIn: filters?.checkIn,
            checkOut: filters?.checkOut,
            guests: bestOffer.guests,
            childrenAges: bestOffer.childrenAges,
            provisionType: bestOffer.provision.type,
            ratePlanId: bestOffer.ratePlan.id,
            roomTypeId: firstApartment.id,
        };

        return urlHelpers.getCheckoutUrl(checkoutQueryParams);
    }),
    selectCurrency: createSelector(
        selectSelfState,
        state => state.currentAccommodation?.convertedRatesCurrency,
    ),
    selectVariantUrl: (variant: Maybe<Variant>, apartmentId: string) =>
        createSelector(selectSelfState, state => {
            const { filters } = state;

            if (!variant) {
                return undefined;
            }

            const checkoutQueryParams = {
                accommodationId:
                    state.currentAccommodation &&
                    urlHelpers.extractNumericId(state.currentAccommodation.id),
                checkIn: filters?.checkIn,
                checkOut: filters?.checkOut,
                guests: variant.guests,
                childrenAges: variant.childrenAges,
                provisionType: variant.provision.type,
                ratePlanId: variant.ratePlan.id,
                roomTypeId: apartmentId,
            };

            return urlHelpers.getCheckoutUrl(checkoutQueryParams);
        }),
    selectUnavailabilityReason: createSelector(
        selectSelfState,
        state => state?.currentAccommodation?.stay?.reasonCode,
    ),
    selectSelectedVariant: createSelector(selectSelfState, state => state.selectedVariant),
    selectSelectedApartmentId: createSelector(selectSelfState, state => state.selectedApartmentId),
    selectApartmentImage: (apartmentId: string | undefined) =>
        createSelector(selectSelfState, state => {
            if (!apartmentId) {
                return undefined;
            }

            const apartment = state.currentAccommodation?.apartments?.find(
                (apartment: Maybe<Apartment>) => apartment?.id === apartmentId,
            );

            if (!apartment || !apartment.images || apartment.images.length === 0) {
                return undefined;
            }

            const defaultImage = apartment.images?.find((image: Maybe<Image>) => !!image?.default);
            const firstImage = apartment.images[0];

            return defaultImage?.url || firstImage?.url;
        }),
    selectIsAccommodationEmpty: createSelector(
        selectSelfState,
        state =>
            !state?.errors?.length &&
            (isNil(state?.currentAccommodation) ||
                !state?.currentAccommodation?.apartments?.length),
    ),
    selectAccommodationWithCurrentApartment: (
        roomTypeId: string,
        ratePlanId: string,
        provisionType: string,
    ) =>
        createSelector(selectSelfState, state => {
            if (isNil(state.currentAccommodation)) {
                return null;
            }

            const filteredApartments = state.currentAccommodation?.apartments
                ?.map(apartment => {
                    if (apartment?.id !== roomTypeId) {
                        return null;
                    }

                    const filteredVariants = apartment?.variants?.filter(
                        variant =>
                            variant?.ratePlan.id === ratePlanId &&
                            variant.provision.type === provisionType,
                    );

                    if (filteredVariants?.length === 0) {
                        return null;
                    }

                    return {
                        ...apartment,
                        variants: filteredVariants,
                    };
                })
                .filter(apartment => apartment !== null);

            return {
                ...state.currentAccommodation,
                apartments: filteredApartments,
            };
        }),
    selectRangePickerDayConfig: createSelector(
        selectSelfState,
        state => state?.rangePickerDayConfig,
    ),
    selectRangePickerExcludedDays: createSelector(
        selectSelfState,
        state => state.rangePickerExcludedDates,
    ),
    selectIsCalendarAvailabilityLoading: createSelector(
        selectSelfState,
        state => state.isCalendarAvailabilityLoading === LoadingState.PENDING,
    ),
    selectCalendarAvailabilityErrors: createSelector(
        selectSelfState,
        state => state.calendarAvailabilityErrors,
    ),
    selectCalendarAvailabilityErrorText: createSelector(
        selectSelfState,
        state => state.calendarAvailabilityErrorText,
    ),
    selectIsDatesAvailabilityError: createSelector(
        selectSelfState,
        state =>
            !!state?.currentAccommodation?.stay?.reasonCode &&
            [
                ReasonCode.CutOffDaysNotReached,
                ReasonCode.HotelFull,
                ReasonCode.HotelClosed,
                ReasonCode.Inactive,
                ReasonCode.MaintenanceInterval,
                ReasonCode.MaximalNightsExceeded,
                ReasonCode.MinimalNightsNotReached,
                ReasonCode.NoArrivalCheckInDate,
                ReasonCode.NoDepartureCheckOutDate,
                ReasonCode.NoRoomsAvailable,
                ReasonCode.ReservationTooLong,
            ].includes(state.currentAccommodation.stay.reasonCode),
    ),
};

const observers = {
    makeReservationObserver: makeReservation$.asObservable(),
};

export { slice, actions, selectors, observers };
